<template>
  <div class="switcher-header">
    <el-menu
      :default-active="activeMenu"
      mode="horizontal"
      :ellipsis="false"
      background-color="rgba(0, 0, 0, 0)"
      text-color="#fff"
    >
      <el-menu-item index="/">
        <NuxtLink to="/" class="text-dec"> 首页 </NuxtLink>
      </el-menu-item>
      <!-- <el-menu-item index="2">
        <NuxtLink to="/version" class="text-dec"> 版本介绍 </NuxtLink>
      </el-menu-item> -->
      <el-sub-menu index="/solution">
        <template #title>行业方案</template>
        <el-menu-item index="/solution/health">
          <NuxtLink to="/solution/health" class="text-dec activeColor a-block">
            医疗
          </NuxtLink>
        </el-menu-item>
        <el-menu-item index="/solution/tran">
          <NuxtLink to="/solution/tran" class="text-dec activeColor a-block">
            教育
          </NuxtLink>
        </el-menu-item>
        <el-menu-item index="/solution/science">
          <NuxtLink to="/solution/science" class="text-dec activeColor a-block">
            科技
          </NuxtLink>
        </el-menu-item>
        <el-menu-item index="/solution/play">
          <NuxtLink to="/solution/play" class="text-dec activeColor a-block">
            电商娱乐
          </NuxtLink>
        </el-menu-item>
      </el-sub-menu>

      <el-menu-item index="/case/list">
        <NuxtLink to="/case/list" class="text-dec"> 案例中心 </NuxtLink>
      </el-menu-item>
      <el-menu-item index="/material">
        <NuxtLink to="/material" class="text-dec"> 素材中心 </NuxtLink>
      </el-menu-item>
      <el-sub-menu index="/information">
        <template #title>资料中心</template>
        <el-menu-item index="/whitepage/list">
          <NuxtLink to="/whitepage/list" class="text-dec activeColor a-block">
            白皮书
          </NuxtLink>
        </el-menu-item>
        <el-menu-item index="/information">
          <NuxtLink to="/information" class="text-dec activeColor a-block">
            帮助中心
          </NuxtLink>
        </el-menu-item>
      </el-sub-menu>
      <el-menu-item index="/about">
        <NuxtLink to="/about" class="text-dec"> 关于我们 </NuxtLink>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script setup>
const route = useRoute()
const activeMenu = computed(() => {
  return route.path
})
</script>

<style lang="scss" scoped>
.text-dec {
  text-decoration: none;
  color: #000;
}
.switcher-header {
  :deep(.el-menu.el-menu--horizontal) {
    border: none;
  }
  :deep(.el-menu.el-menu--horizontal > .el-menu-item) {
    height: 72px;
    line-height: 72px;
  }
  :deep(.el-sub-menu, .el-menu--horizontal) {
    height: 72px;
    line-height: 72px;
  }
  :deep(.el-menu-item) {
    font-size: var(--el-font-size-medium);
  }
  :deep(.el-sub-menu__title) {
    font-size: 16px;
  }
}

/* 子菜单的 NuxtLink */
.el-menu--horizontal .el-menu .el-menu-item.is-active .activeColor {
  width: 100%;
  display: inline-block;
  color: var(--el-menu-active-color);
}
.a-block {
  width: 100%;
  display: inline-block;
}
</style>
