<template>
  <div>
    <div class="service">
      <div class="server-img" @click="handleOpen">
        <img style="width: 64px" src="~/assets/images/fw/serve-1.png" alt="" />
        <div class="img-bg">在线咨询</div>
      </div>

      <div class="service-bg">
        <div @click="handleOpen">
          <div>
            <img class="img-w" src="~/assets/images/fw/money.png" alt="" />
          </div>
          <div>报价咨询</div>
        </div>
        <div class="content">
          <div class="img-center">
            <img class="img-w wechat-icon" src="~/assets/images/fw/we-chat.png" alt="" />
            <img
              class="img-w wechat-icon-hover"
              src="~/assets/images/fw/we-chat-hover.png"
              alt=""
            />
          </div>
          <div>微信指导</div>
          <div class="consult">
            <div class="arrow-right"></div>
            <div class="qrcode">
              <img class="qrcode-img" src="~/assets/images/fw/foot-2.png" alt="" />
              <div class="des">微信指导</div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="img-center">
            <img class="img-w wechat-icon" src="~/assets/images/fw/phone.png" alt="" />
            <img
              class="img-w wechat-icon-hover"
              src="~/assets/images/fw/phone-hover.png"
              alt=""
            />
          </div>
          <div>电话咨询</div>
          <div class="consult">
            <div class="arrow-right"></div>
            <div class="mobile">
              <img class="mobile-img" src="~/assets/images/fw/phone-hover.png" alt="" />
              <div class="des">010-86469257</div>
            </div>
            <div></div>
          </div>
        </div>
        <div class="content">
          <div class="img-center">
            <img class="img-w wechat-icon" src="~/assets/images/fw/qrcode.png" alt="" />
            <img
              class="img-w wechat-icon-hover"
              src="~/assets/images/fw/qrcode-hover.png"
              alt=""
            />
          </div>
          <div>客户群</div>
          <div class="consult">
            <div class="arrow-right"></div>
            <div class="qrcode">
              <img class="qrcode-img" src="~/assets/images/fw/foot-1.png" alt="" />
              <div class="des">客户群</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CustomerService
      :isShowCustomerServiceWindow="isShowCustomerServiceWindow"
      @handleCloseCustomerService="handleCloseCustomerService"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
const isShowCustomerServiceWindow = ref<boolean>(false);

const handleOpen = () => {
  isShowCustomerServiceWindow.value = true;
};
const handleCloseCustomerService = (falg: boolean) => {
  isShowCustomerServiceWindow.value = falg;
};
</script>

<style lang="scss" scoped>
.service {
  border-radius: 5%;
  position: fixed;
  bottom: 50px;
  right: 15px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .server-img {
    position: relative;
    width: 64px;
    margin-bottom: 41px;
    cursor: pointer;
    .img-bg {
      position: absolute;
      left: 0;
      bottom: -3px;
      width: 64px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      background: #ffa24a;
      border-radius: 12px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .service-bg {
    width: 64px;
    height: 314px;
    padding: 18px 0;
    border-radius: 32px;
    font-size: 11px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: #999999;
    cursor: pointer;
    .img-w {
      width: 24px;
      padding-bottom: 10px;
    }
    .img-center {
      text-align: center;
    }
    .content {
      padding-top: 20px;
      .wechat-icon-hover {
        display: none;
      }
      .wechat-icon {
        display: inline-block;
      }
      .consult {
        display: none;
        width: 248px;
        height: 104px;
        background: #ffffff;
        box-shadow: 0px 6px 12px 0px rgba(188, 220, 206, 0.59);
        position: absolute;
        top: 0px;
        right: 74px;
        background-color: #fff;
        padding: 16px;
        z-index: 100;
        border-radius: 10px;
        box-sizing: border-box;
        .qrcode {
          display: flex;
          align-items: center;
          height: 100%;
          .qrcode-img {
            width: 72px;
            height: 72px;
            margin-right: 10px;
          }
          .des {
            box-sizing: border-box;
            line-height: 20px;
            color: #3d3d3d;
            font-size: 14px;
            text-align: left;
          }
        }
        .mobile {
          display: flex;
          align-items: center;
          height: 100%;
          .mobile-img {
            width: 48px;
            height: 48px;
            margin-right: 10px;
          }
          .des {
            font-weight: 500;
            color: #3d3d3d;
            font-size: 20px;
            line-height: 20px;
            text-align: left;
          }
        }
        .arrow-right {
          position: absolute;
          right: -20px;
          top: 44px;
          width: 0;
          height: 0;
          border: 10px solid;
          border-color: transparent transparent transparent #fff;
        }
      }
    }
    .content:hover {
      position: relative;
      color: var(--el-menu-active-color);
    }
    .content:hover .consult {
      display: block;
    }
    .content:hover .wechat-icon {
      display: none;
    }
    .content:hover .wechat-icon-hover {
      display: inline-block;
    }
  }
}
</style>
