<template>
  <div>
    <header>
      <div class="logo">
        <img src="~/assets/images/fw/logo.png" alt="医百导播台logo" />
      </div>
      <ClientOnly>
        <AppHeader />
      </ClientOnly>

      <ClientOnly>
        <div class="header-foot">
          <div v-if="state.userinfo && state.userinfo.id" class="userinfo">
            <el-button type="primary" @click="goFreeExperience"
              >进入导播台</el-button
            >
            <el-popover
              placement="top-start"
              title=""
              :width="200"
              trigger="hover"
              :teleported="false"
              :show-arrow="false"
            >
              <div class="user-popover">
                <div
                  class="user-popover-item"
                  @mouseenter="state.activeIndex = 1"
                  :class="{ activeStyle: state.activeIndex == 1 }"
                >
                  <span class="user-popover-item-title">账户昵称</span>
                  <span
                    >{{ state.userinfo.nickname || state.userinfo.username }}
                  </span>
                </div>
                <div
                  class="user-popover-item"
                  @mouseenter="state.activeIndex = 2"
                  :class="{ activeStyle: state.activeIndex == 2 }"
                >
                  <span class="user-popover-item-title">套餐名称</span>
                  <span v-if="state.packageName">{{ state.packageName }}</span>
                </div>
                <div
                  class="user-popover-item"
                  @mouseenter="state.activeIndex = 3"
                  :class="{ activeStyle: state.activeIndex == 3 }"
                >
                  <span class="user-popover-item-title">有效期</span>
                  <span v-if="state.beginTime && state.endTime"
                    >{{ formattedDate(state.beginTime) }}至{{
                      formattedDate(state.endTime)
                    }}</span
                  >
                </div>
                <div
                  class="user-popover-item login-out"
                  @mouseenter="state.activeIndex = 4"
                  :class="{ activeStyle: state.activeIndex == 4 }"
                  @click="loginOut"
                >
                  退出
                </div>
              </div>
              <template #reference>
                <img
                  class="user_avatar"
                  v-if="state.userinfo && state.userinfo.avatar"
                  :src="state.userinfo.avatar"
                  alt=""
                />
                <img
                  v-else
                  class="user_avatar"
                  src="~/assets/images/solution/user-default-avater.jpg"
                  alt=""
                />
              </template>
            </el-popover>
          </div>
          <div v-else>
            <el-button type="primary" @click="handleLogin('register')">
              注册免费体验
            </el-button>
            <el-button type="primary" @click="handleLogin('login')">
              登录
            </el-button>
          </div>
        </div>
      </ClientOnly>
    </header>
    <floatingWindow />
    <slot />
    <AppFooter />
  </div>
</template>

<script lang="ts" setup>
// import { defineComponent } from "vue";
import { useDefaultRequest } from '@/composables/request'
import { useRouter } from 'vue-router'
import { goFreeExperience, getUserInfo, getLoginState } from '@/utils/index'

const router = useRouter()

const state = reactive({
  userinfo: null,
  packageName: '',
  beginTime: '',
  endTime: '',
  activeIndex: null
})

onNuxtReady(async () => {
  state.userinfo = getUserInfo()
})

const pageOnShowFn = () => {
  const self = this
  if (typeof window !== 'undefined') {
    window.addEventListener('visibilitychange', function () {
      if (!document.hidden) {
        setTimeout(() => {
          state.userinfo = getUserInfo()
          getPackage()
        }, 800)
      }
    })
  }
}

const handleLogin = (type: string | null) => {
  router.push({
    path: '/login',
    query: {
      type
    }
  })
}

const getHostHrefFn = () => {
  const env = useRuntimeConfig().public.env
  let host = null
  if (env === 'development') {
    // 开发
    host = 'https://switcher.100url.cn/swgw/switcherApi'
  } else if (env === 'test') {
    // 测试
    host = 'https://switcher.test.100mix.cn/swgw/switcherApi'
  } else {
    // 线上
    host = 'https://console.100mix.cn/swgw/switcherApi'
  }
  return host
}
const getPackage = async () => {
  const host = getHostHrefFn()
  const url = `${host}/user/check/package`
  const token = getLoginState()?.accessToken || ''
  if (token) {
    const res: any = await useDefaultRequest.get(
      url,
      {},
      {
        needBaseUrl: false,
        newHeaders: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    )
    state.packageName = res.data?.baseConfig.name
    state.beginTime = res.data?.beginTime
    state.endTime = res.data?.endTime
  }
}
const formattedDate = (data: string) => {
  const date = new Date(data)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

getPackage()
pageOnShowFn()
</script>

<script lang="ts">
import { removeLoginDataFn } from '@/utils/index'
import { defineComponent } from 'vue'
export default defineComponent({
  methods: {
    loginOut() {
      ;(this.$ybGuard as any).logout().then(() => {
        removeLoginDataFn()
        location.reload()
      })
    }
  }
})
</script>
<style lang="less" scoped>
.swicter-body {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 72px;
}
.logo {
  width: 216px;
  height: 72px;
}
header {
  padding-left: 64px;
  padding-right: 64px;
  display: flex;
  justify-content: space-between;
  height: 72px;
  align-items: center;
  width: 100%;
  min-width: 1400px;
  position: absolute;
  left: 0;
  top: 0;
  height: 72px;
}

footer {
  background-color: blueviolet;
}
.user_avatar {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 48px;
}
// .userinfo {
//   img {
//     display: block;
//     width: 40px;
//     height: 40px;
//     border-radius: 50%;
//     margin-left: 48px;
//   }
// }
.header-foot {
  :deep(.el-popover) {
    width: 286px !important;
    background: #363f4c;
    border-radius: 4px;
    border: none;
    padding: 0;
  }
  .user-popover {
    padding: 8px 0;
  }
  .user-popover-item {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    padding-left: 16px;
    &-title {
      margin-right: 24px;
    }
  }
  .activeStyle {
    background: #495464;
  }
  .login-out {
    color: #ff4d4f;
    cursor: pointer;
  }
}
</style>
